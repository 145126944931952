/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import validator from "validator";
import toast from "react-hot-toast";
import { TextField, IconButton, InputAdornment } from '@mui/material';
import Button from "../common/ButtonV2/Button";
import apiClient from '../../utils/apiClient';
import { getReferralFromCookie, clearReferralCookie } from "../../utils/referral";
import { Eye, EyeOff } from 'lucide-react';

// Common passwords for validation
const commonPasswords = [
  "123456", "password", "123456789", "12345678", "12345", "1234567", "qwerty",
  "abc123", "111111", "123123"
];

// Password validation function
const validatePassword = (password, userAttributes = {}) => {
  const { username, email } = userAttributes;

  // Check if it's entirely numeric first
  if (password && !Number.isNaN(Number(password))) {
    return ["Password cannot be entirely numeric"];
  }

  // Then check username/email similarity
  if (username && password.toLowerCase().includes(username.toLowerCase())) {
    return ["Password is too similar to your username."];
  }
  if (email && password.toLowerCase().includes(email.toLowerCase().split("@")[0])) {
    return ["Password is too similar to your email."];
  }

  // Then check if it's a common password
  if (commonPasswords.includes(password)) {
    return ["Password is too common"];
  }

  // Check length last
  if (password.length < 8) {
    return ["Password must be 8+ characters long"];
  }

  return [];
};

const RegisterForm = ({ onSwitchToLogin, setIsLoading }) => {
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [validate, setValidate] = useState({});
  const [apiErrors, setApiErrors] = useState({});
  const [showPasswords, setShowPasswords] = useState(false);

  const handlePasswordChange = (value) => {
    setPassword(value);
    const passwordErrors = validatePassword(value, { username: displayName, email });
    setValidate((prev) => ({
      ...prev,
      password: passwordErrors.length > 0 ? passwordErrors : null,
      rePassword: rePassword && value !== rePassword ? "Passwords do not match" : null
    }));
  };

  const handleRePasswordChange = (value) => {
    setRePassword(value);
    setValidate(prev => ({
      ...prev,
      rePassword: value && value !== password ? "Passwords do not match" : null
    }));
  };

  const handleDisplayNameChange = (value) => {
    setDisplayName(value);
    setValidate(prev => ({
      ...prev,
      username: null
    }));
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    if (value && !validator.isEmail(value)) {
      setValidate(prev => ({
        ...prev,
        email: "Email is not valid"
      }));
    } else {
      setValidate(prev => ({
        ...prev,
        email: null
      }));
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const normalizedEmail = email.toLowerCase();

    let validateTemp = {};
    if (validator.isEmpty(displayName)) {
      validateTemp.username = "This field can't be empty";
    }

    if (validator.isEmpty(normalizedEmail)) {
      validateTemp.email = "This field can't be empty";
    }

    if (!validator.isEmail(normalizedEmail)) {
      validateTemp.email = "Email is not valid";
    }

    const passwordErrors = validatePassword(password, { username: displayName, email });
    if (passwordErrors.length > 0) {
      validateTemp.password = passwordErrors;
    }

    if (validator.isEmpty(rePassword)) {
      validateTemp.rePassword = "This field can't be empty";
    } else if (password !== rePassword) {
      validateTemp.rePassword = "Password not match!";
    }

    setValidate({ ...validateTemp });
    if (Object.keys(validateTemp).length > 0) return; // Prevent submission if there are validation errors

    try {
      setIsLoading(true);
      setApiErrors({});

      const referralCode = getReferralFromCookie();

      const { status, data } = await apiClient.makeRequest({
        url: "/api/auth/register",
        method: "POST",
        data: {
          password,
          password_confirm: rePassword,
          username: displayName,
          email: normalizedEmail,
          referrer_id: referralCode,
        }
      });

      if (status === 201) {
        clearReferralCookie();

        try {
          const url = new URL(window.location.href);
          url.searchParams.delete("ref");
          window.history.replaceState({}, "", url.toString());
        } catch (error) {
          console.error("Error clearing URL parameter:", error);
        }
        toast.success("Account created! Please login!");
        onSwitchToLogin();

        if (window.fbq) {
          fbq("track", "CompleteRegistration", {
            status: true,
            email: normalizedEmail,
          });
        } else {
          console.log("Facebook pixel not found (window.fbq is not available)");
        }
      } else {
        setApiErrors(data || {});
      }
    } catch (err) {
      console.error(err);
      setApiErrors({ 
        non_field_errors: [err.message || 'An error occurred during registration']
      });
    } finally {
      setIsLoading(false);
    }
  }

  const getFieldErrors = (field) =>
    validate[field] ? Array.isArray(validate[field]) ? validate[field] : [validate[field]] : apiErrors[field] || [];

  // Add this style object for MUI components
  const inputStyles = {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#1A1A1D',
      '& fieldset': {
        borderColor: '#3A3A41',
      },
      '&:hover fieldset': {
        borderColor: '#3A3A41',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#3A3A41',
      },
    },
    '& input': {
      color: 'white',
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px #1A1A1D inset',
        WebkitTextFillColor: 'white',
      },
    },
    '& label': {
      color: 'rgba(255, 255, 255, 0.6)',
    },
    '& .MuiFormHelperText-root': { // Smaller error text
      fontSize: '0.75rem',
      marginTop: '4px',
    },
    '& .MuiInputAdornment-root': {
      color: 'rgba(255, 255, 255, 0.6)',
    },
  };

  return (
    <>
      <h1 className="mb-6 font-header text-5xl font-medium uppercase text-white">
        Create an <span className="text-red-500">Account</span>
      </h1>
      
      <form className="mb-4 flex flex-col gap-4" onSubmit={handleSubmit}>
        {apiErrors.non_field_errors && (
          <ul className="list-disc pl-4 text-red-500">
            {apiErrors.non_field_errors.map((err, index) => (
              <li key={index}>{err}</li>
            ))}
          </ul>
        )}

        <div>
          <p className="mb-2 text-white/60">User Name</p>
          <TextField
            fullWidth
            type="text"
            name="custom-display-name"
            autoComplete="off"
            placeholder="Enter your display name"
            value={displayName}
            onChange={(e) => handleDisplayNameChange(e.target.value)}
            error={!!getFieldErrors("username")[0]}
            helperText={getFieldErrors("username")[0]}
            inputProps={{
              'data-lpignore': 'true',
              'data-form-type': 'other'
            }}
            sx={inputStyles}
          />
        </div>

        <div>
          <p className="mb-2 text-white/60">Email</p>
          <TextField
            fullWidth
            type="email"
            name="email"
            autoComplete="off"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => handleEmailChange(e.target.value)}
            error={!!getFieldErrors("email")[0]}
            helperText={getFieldErrors("email")[0]}
            sx={inputStyles}
          />
        </div>

        <div>
          <p className="mb-2 text-white/60">Password</p>
          <TextField
            fullWidth
            type={showPasswords ? 'text' : 'password'}
            name="password"
            autoComplete="new-password"
            placeholder="Enter a password"
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
            error={!!getFieldErrors("password")[0]}
            helperText={getFieldErrors("password")[0]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPasswords(!showPasswords)}
                    edge="end"
                    sx={{ color: 'rgba(255, 255, 255, 0.6)' }}
                  >
                    {showPasswords ? <EyeOff size={20} /> : <Eye size={20} />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={inputStyles}
          />
        </div>

        <div>
          <p className="mb-2 text-white/60">Confirm Password</p>
          <TextField
            fullWidth
            type={showPasswords ? 'text' : 'password'}
            name="new-password-confirm"
            autoComplete="new-password"
            placeholder="Confirm your password"
            value={rePassword}
            onChange={(e) => handleRePasswordChange(e.target.value)}
            error={!!getFieldErrors("rePassword")[0]}
            helperText={getFieldErrors("rePassword")[0]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPasswords(!showPasswords)}
                    edge="end"
                    sx={{ color: 'rgba(255, 255, 255, 0.6)' }}
                  >
                    {showPasswords ? <EyeOff size={20} /> : <Eye size={20} />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={inputStyles}
          />
        </div>

        <div className="mb-4 text-center text-xs text-white/30">
          By clicking on Create Account you agree to our{" "}
          <a
            href="/terms"
            target="_blank"
            className="cursor-pointer text-accent hover:underline"
          >
            Terms of Service.
          </a>
        </div>
        <Button
          buttonType="submit"
          type="accent-background-text-black"
          className="font-bold"
        >
          Create Account
        </Button>
      </form>
      <div className="flex flex-col items-center gap-2">
        <p className="text-[#AEAFB1]">
          Already have an account?{" "}
          <button
            className="cursor-pointer text-accent hover:underline"
            onClick={onSwitchToLogin}
          >
            Log in
          </button>
        </p>
      </div>
    </>
  );
};

export default RegisterForm;
