// Base API Error class
export class APIError extends Error {
  constructor(message, status, code) {
    super(message);
    this.name = this.constructor.name;
    this.status = status;
    this.code = code;
  }
}

// Specific error types
export class AuthenticationError extends APIError {
  constructor(message = 'Authentication required') {
    super(message, 401, 'UNAUTHORIZED');
  }
}

export class ForbiddenError extends APIError {
  constructor(message = 'Permission denied') {
    super(message, 403, 'FORBIDDEN');
  }
}

export class NetworkError extends APIError {
  constructor(message = 'Network connectivity issue') {
    super(message, 0, 'NETWORK_ERROR');
  }
}

export class NotFoundError extends APIError {
  constructor(resource) {
    super(`Resource not found: ${resource}`, 404, 'NOT_FOUND');
  }
}

export class ServerError extends APIError {
  constructor(message = 'Internal server error') {
    super(message, 500, 'SERVER_ERROR');
  }
} 