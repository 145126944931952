import * as Sentry from '@sentry/nextjs';

export class SentryLogger {
  static async logError(error, context = {}) {
    const environmentInfo = this.getEnvironmentInfo();
    const errorName = this.getErrorName(error);
    
    Sentry.captureException(error, {
      level: 'error',
      tags: {
        errorType: context.type || 'Unknown',
        errorContext: context.errorContext || 'General',
        statusCode: error.response?.status?.toString()
      },
      extra: {
        ...context,
        errorName,
        errorDetails: {
          message: error.message,
          stack: error.stack,
          code: error.code,
        },
        response: error.response && {
          status: error.response.status,
          statusText: error.response.statusText,
          data: error.response.data,
          headers: error.response.headers,
        },
        request: error.config && {
          url: error.config.url,
          method: error.config.method,
          headers: error.config.headers,
          data: error.config.data,
        },
        environment: environmentInfo,
      }
    });

    return Sentry.flush(2000);
  }

  static getErrorName(error) {
    if (error.response) {
      return `HTTP${error.response.status}Error: ${error.response.statusText}`;
    }
    if (error.code === 'ECONNABORTED') {
      return 'TimeoutError';
    }
    return error.name || 'UnknownError';
  }

  static getEnvironmentInfo() {
    if (typeof window === 'undefined') {
      return { environment: 'server' };
    }

    return {
      userAgent: window.navigator.userAgent,
      platform: window.navigator.platform,
      language: window.navigator.language,
      viewport: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      url: window.location.href,
    };
  }
} 