import axios from 'axios';
import { API_URL } from '../const';
import { ErrorHandler } from './apiClient/errorHandlers';
import { createAuthInterceptor, createErrorInterceptor } from './apiClient/interceptors';

class ApiClient {
  constructor(baseURL = API_URL) {
    this.client = axios.create({
      baseURL,
      timeout: 15000,
    });

    this.client.interceptors.request.use(createAuthInterceptor());
    this.client.interceptors.response.use(
      (response) => response,
      createErrorInterceptor()
    );
  }

  async makeRequest(config, errorContext = {}) {
    try {
      // Determine request type
      const isNextRoute = config.url.startsWith('/api/');
      const isFullUrl = config.url.startsWith('http://') || config.url.startsWith('https://');
      
      let response;
      if (isNextRoute) {
        // Next.js API routes
        response = await axios(config);
      } else if (isFullUrl) {
        // External URLs (like competition URLs)
        response = await axios({
          ...config,
          baseURL: '' // Prevent baseURL being prepended
        });
      } else {
        // Backend API routes
        response = await this.client(config);
      }
      
      return {
        status: response.status,
        data: response.data,
        ...response.data  // Spread response data for backward compatibility
      };
    } catch (error) {
      return ErrorHandler.handle(error, config, errorContext);
    }
  }
}

// Create singleton instance
const apiClient = new ApiClient();
export default apiClient;
