import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faXTwitter,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import Image from "next/image";
import { NAVIGATION } from "const";
import Link from "next/link";
import {
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopesBulk,
  faEnvelopeSquare,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => (
  <div className="border-t-[0.5px] border-solid border-t-[#1F242F]">
    <div className="container mx-auto py-10 pt-14">
      <div className="flex flex-col gap-5 md:flex-row md:justify-between">
        <div>
          <div className="flex flex-col gap-4">
            <Image
              src="/img/FightRealmLogo.png"
              width={200}
              height={30}
              className="-mt-1"
              alt="fightRealmLogo"
            />
          </div>
        </div>
        <div className="flex cursor-pointer justify-between text-sm font-medium text-gray-400 md:text-base">
          <div className="flex flex-col gap-6 md:flex-row">
            {NAVIGATION.map((el) => (
              <Link href={el.href} className="hover:underline" key={el.name}>
                {el.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-10 border-t-[0.5px] border-solid border-t-[#1F242F]"></div>
      <div className="my-6 flex flex-col justify-between gap-3 md:flex-row">
        <div className="flex items-center gap-6 text-sm text-gray-400 md:text-base">
          <span className=" ">@ {new Date().getFullYear()} Fight Realm</span>
          <Link href="/terms" className="hover:underline">
            Terms and conditions
          </Link>
          <Link href="/privacy-policy" className="hover:underline">
            Privacy Policy
          </Link>
        </div>
        <div className="flex gap-4">
          <a
            className="text-[#85888E] transition hover:text-white"
            href="https://www.facebook.com/fightrealm"
            target="_blank"
          >
            <FontAwesomeIcon icon={faFacebook} className="text-lg" />
          </a>
          <a
            className="text-[#85888E] transition hover:text-white"
            href="https://x.com/fightrealm_off"
            target="_blank"
          >
            <FontAwesomeIcon icon={faXTwitter} className="text-lg" />
          </a>
          <a
            className="text-[#85888E] transition hover:text-white"
            href="https://www.youtube.com/@FightRealm_Official"
            target="_blank"
          >
            <FontAwesomeIcon icon={faYoutube} className="text-lg" />
          </a>
          <a
            className="text-[#85888E] transition hover:text-white"
            href="https://www.instagram.com/fightrealm_official/"
            target="_blank"
          >
            <FontAwesomeIcon icon={faInstagram} className="text-lg" />
          </a>
          <a
            className="text-[#85888E] transition hover:text-white"
            href="mailto:info@fightrealm.com"
            target="_blank"
          >
            <FontAwesomeIcon icon={faEnvelope} className="text-lg" />
          </a>
        </div>
      </div>
      <div className='text-center w-14/16 mx-auto mt-4 border-y-[0.5px] border-solid border-y-[#1F242F] py-10'>
        <span className="text-[#85888E] text-center text-[12px]">
          The individual athletes featured on this website are not sponsored or endorsed by Fight Realm Pty Ltd. Fight Realm Pty Ltd is not affiliated with, nor endorsed by, any MMA promotion, organization, or its athletes. Any reference to MMA promotions, fighters, or related entities is for informational purposes only and does not imply any formal relationship or endorsement.

          All trademarks, service marks, registered trademarks, and registered service marks mentioned, displayed, or referenced on this website are the property of their respective owners. Fight Realm Pty Ltd does not claim any rights or ownership over such marks, and their inclusion on this website does not imply any commercial relationship or endorsement by the trademark holders.

          Additionally, all media assets, including but not limited to photographs, videos, logos, and other intellectual property featured on this website, remain the exclusive property of their respective owners. These media are used solely to reference the work of the original creators and are not indicative of any partnership, affiliation, or endorsement by the owners of the media.

          The use of such media on this website does not create any implied connection between Fight Realm Pty Ltd and the respective media owners, nor does it imply any approval or endorsement by the media owner of this website or its content. The media owners retain all rights to their respective intellectual property and media assets.
        </span>
      </div>
    </div>
  </div>
);

Footer.propTypes = {
  // title: PropTypes.string.isRequired,
};

Footer.defaultProps = {
  // title: '',
};

export default Footer;
