import React, { useMemo, forwardRef, useCallback, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import Image from "next/image";
import PropTypes from "prop-types";
import { CaretUp, CaretDown } from "@phosphor-icons/react";
import Pf from "../../public/img/pf.jpg";
import { useProfile } from "../../api/client/profile";

const Leaderboard = forwardRef(
  ({ leaderboard, className, fixedHeight, isCompPage }, ref) => {
    const { data } = useProfile();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Adjust this value for your mobile breakpoint
      };

      handleResize(); // Check on initial render
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const getRankChangeStyle = (change) => {
      if (change < 0) {
        return "bg-red-500/20 text-red-500";
      }
      if (change > 0) {
        return "bg-green-500/20 text-green-500";
      }
      return "";
    };

    const RankCellRenderer = useCallback((params) => {
      
      const change = params.data.lastRank - params.data.rank;
      return (
        <div className="flex items-center gap-2">
          <span className="">#{isCompPage ? params.data.query_rank || "N/A" : params.data.rank || "N/A"}</span>
          {params.data.lastRank !== 0 && !isCompPage && (
            <span
              className={`flex items-center gap-1 rounded-full px-2 py-0.5 text-sm ${getRankChangeStyle(change)}`}
            >
              {change < 0 ? (
                <CaretDown weight="bold" />
              ) : (
                <CaretUp weight="bold" />
              )}
              {Math.abs(change)}
            </span>
          )}
        </div>
      );
    }, [isCompPage]);

    const UserCellRenderer = (params) => (
      <div className="flex items-center gap-3">
        <Image
          src={params.data.pf || Pf}
          width={24}  // Adjust size for mobile
          height={24} // Adjust size for mobile
          alt={params.value}
          className="h-6 w-6 rounded-full" // Adjust size for mobile
        />
        <span>{params.data.user}</span>
      </div>
    );

    const PointsCellRenderer = (params) => (
      <div className="flex items-center gap-2">
        <span>{params.data.lastScore}</span>
      </div>
    );

    const TotalPointsCellRenderer = (params) => (
      <div className="flex items-center gap-2">
        <span>{isCompPage ? params.data.query_season_points || 0 : params.data.totalScore || 0}</span>
      </div>
    );

    const columnDefs = useMemo(
      () => [
        {
          headerName: "Rank",
          field: "rank",
          width: isMobile ? null : 120,  // Use default or adjusted width for desktop
          cellRenderer: RankCellRenderer,
          sortable: true,
          flex: isMobile ? 0.3 : 0, // Adjust for mobile
        },
        {
          headerName: "User",
          field: "user",
          cellRenderer: UserCellRenderer,
          flex: isMobile ? 0.4 : 1, // Adjust for mobile
          minWidth: isMobile ? 175 : 200,
          sortable: true,
        },
        {
          headerName: isMobile ? "Points" : "Total Points",
          field: "totalScore",
          cellRenderer: TotalPointsCellRenderer,
          width: isMobile ? null : 200, // Use default or adjusted width for desktop
          sortable: true,
          comparator: (valueA, valueB) => valueB - valueA,
          flex: isMobile ? 0.3 : 0, // Adjust for mobile
        },
        ...(isCompPage
          ? [] // Hide certain columns on competition pages
          : [
        {
          headerName: "Last Points",
          field: "lastScore",
          cellRenderer: PointsCellRenderer,
          width: isMobile ? null : 200, // Use default or adjusted width for desktop
          sortable: true,
          comparator: (valueA, valueB) => valueB - valueA,
          // Hide this column on mobile
          hide: isMobile,
        },
      ]),
      ],
      [RankCellRenderer, isMobile, isCompPage],
    );

    const defaultColDef = useMemo(
      () => ({
        filter: false,
        sortable: true,
      }),
      [],
    );

    const gridOptions = {
      suppressMovableColumns: true,  // Disables column movement
    };

    const pinnedBottomRowData = useMemo(() => {
      if (!data?.username) return [];
      return [leaderboard.find((row) => row.user === data.username)].filter(
        Boolean,
      );
    }, [leaderboard, data?.username]);

    const rowData = useMemo(() => {
      if (!data?.username) return leaderboard;
      return leaderboard.filter((row) => row.user !== data.username);
    }, [leaderboard, data?.username]);

    const getRowStyle = (params) => {
      if (data?.username === params.data.user) {
        return { backgroundColor: "rgba(207,255,63,0.1)" };
      }
      return null;
    };

    return (
      <div
        ref={ref}
        className={`ag-theme-quartz-dark ${className}`}
        style={{
          width: "100%",
          height: fixedHeight ? "600px" : "auto",
          "--ag-header-background-color": "transparent",
          "--ag-borders": "none",
          "--ag-background-color": "transparent",
          "--ag-font-family": "inherit",
          fontSize: isMobile ? "14px !important" : "inherit", // Force font size for mobile
        }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          domLayout={fixedHeight ? undefined : "autoHeight"}
          rowHeight={50}
          suppressCellFocus={true}
          pinnedBottomRowData={pinnedBottomRowData}
          getRowStyle={getRowStyle}
          gridOptions={gridOptions}

        />
      </div>
    );
  },
);

Leaderboard.displayName = "Leaderboard";

Leaderboard.propTypes = {
  leaderboard: PropTypes.array,
  className: PropTypes.string,
  fixedHeight: PropTypes.bool,
  isCompPage: PropTypes.bool, // New prop
};

Leaderboard.defaultProps = {
  className: "",
  fixedHeight: false,
  isCompPage: false, // Default to false
};

export default Leaderboard;
