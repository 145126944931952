import { ACCESS_TOKEN } from '../../const';

export const createAuthInterceptor = () => {
  return (config) => {
    const token = typeof window !== 'undefined' 
      ? localStorage.getItem(ACCESS_TOKEN) 
      : undefined;
    
    config.headers.Authorization = token ? `Token ${token}` : undefined;
    return config;
  };
};

export const createErrorInterceptor = () => {
  return async (error) => {
    return Promise.reject(error);
  };
}; 